import React, { useState } from "react";
import { Modal } from "antd";
import PhoneInput from "react-phone-input-2";
import Button from "@mui/material/Button";
import { openNotificationWithIcon } from "utils";
import * as CustomerApi from "../../../../../api/customers.api";

const AddPhoneNumberModal = ({
  user,
  setShowAddPhoneNumberModal,
  showAddPhoneNumberModal,
}) => {
  const [dialCode, setDialCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  /**
   * Following function is to handle change of phone number field in the form.
   * @author : Vinit
   */
  const handlePhoneNumber = (value, data) => {
    setPhoneNumber(`+${value}`);
    setDialCode(data.dialCode);
  };

  const handleSubmit = async () => {
    if (phoneNumber.length - dialCode.length - 1 !== 10) {
      openNotificationWithIcon(
        "info",
        "info",
        "Phone number must be 10 digits (excluding country code)."
      );
      return;
    }
    const updatedCustomer = await CustomerApi.updateCustomer(user.customer.id, {
      phoneNumber,
    });
    // console.log("Response for customer update api", { updatedCustomer });
    if (updatedCustomer) {
      setShowAddPhoneNumberModal(false);
    }
  };

  return (
    <>
      <Modal
        closable={false}
        visible={showAddPhoneNumberModal}
        footer={[null]}
        className={"addPhoneNumberModal"}
      >
        <div className="d-flex flex-column gap-10 align-items-center text-center">
          <span className="addPhoneNumberModalHeading">Add Phone Number</span>
          <span className="addPhoneNumberModalSubHeading">
            Kindly enter your phone number to post a job.
          </span>
          <div className="addPhoneNumberModalPhoneNumberDiv">
            <PhoneInput
              countryCodeEditable={false}
              name={"phoneNumber"}
              country="us"
              onlyCountries={["in", "gr", "us", "ca", "gb"]}
              className={`p-0`}
              dropdownClass=""
              inputClass="business-plan-phone-input country-code-textarea"
              value={phoneNumber}
              onChange={handlePhoneNumber}
            />
          </div>
          <div className="addPhoneNumberModalButtonDiv">
            <Button onClick={handleSubmit}>Submit</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddPhoneNumberModal;
