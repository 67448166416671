import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal, Alert } from "react-bootstrap";
import moment from "moment";
import { Form, Select, Spin } from "antd";
import FormItem from "../components/FormItem";
import {
  useFetchInvites,
  useInviteUserMutation,
  fetchInvites,
} from "../api/invite.api";
import * as CustomerApi from "../api/customers.api";

import Input from "../components/AuthLayout/Input";
import Box from "../components/common/Box";
import { useUser } from "../../src/context/useContext";
import { openNotificationWithIcon, roleStatus } from "../utils/index";
import * as userService from "../api/users.api";
import * as JobApi from "../api/job.api";
import Loader from "../components/Loader";

import { useAuth } from "../context/authContext";
import InviteDeleteUserModal from "../components/InviteDeleteUserModal";
import { ImportCSVModal } from "components/ImportCSVModal/ImportCSVModal";
import PriorOrExistedUser from "components/ImportCSVModal/PriorOrExistedUser";

const { Option } = Select;

const Invite = () => {
  const { resetPasswordHandler } = useAuth();
  const [showAction, setShowAction] = useState(false);
  const [userRole, setUserRole] = useState("user");
  const { user } = useUser();
  const [showAdminBtn, setShowAdminBtn] = useState(false);
  const [showInviteModal, setShow] = React.useState(false);
  let { data: inviteList } = useFetchInvites();
  const [mainData, setMainData] = useState(inviteList);
  const [loader, setLoader] = useState(false);
  const [openImportCSVModal, setOpenImportCSVModal] = useState(false);
  const [priorOrExistedUSer, setPriorOrExistedUser] = useState({
    list: [],
    showAlert: false,
  });
  const currentInvite = inviteList?.data?.find(
    (inv) => inv.email === user.email
  );
  const [deleteButton, setDeleteButton] = useState(false);
  const [selectDeleteRows, setSelectDeleteRows] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [disableDeleteCheck, setDisableDeleteCheck] = useState(false);
  const [refreshInvitesList, setRefreshInvitesList] = useState();
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState({});
  const [isLoadingSelect, setIsLoadingSelect] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  let [invites, setInvites] = useState([]);
  // This state is used to refresh the user's list to whom we send the invitation
  const [refreshInvites, setRefreshInvites] = useState("");

  let {
    mutate: inviteNewUser,
    isLoading,
    isSuccess,
    data,
  } = useInviteUserMutation();
  const [inviteData, setInviteData] = React.useState({
    email: "",
    role: "user",
    businessName: "",
  });
  const handleClose = () => {
    setShow(false);
    data = {};
  };

  // This will update the setMainData with updated list of invited users
  useEffect(() => {
    (async function () {
      let inviteData = await fetchInvites();

      setMainData(inviteData);
    })();
  }, [refreshInvitesList, refreshInvites]);

  useEffect(() => {
    let invites = mainData?.data?.filter((inv) => inv.email !== user.email);
    if (currentInvite) {
      invites.unshift(currentInvite);
    }
    setInvites(invites);
  }, [mainData, refreshInvitesList, refreshInvites]);

  useEffect(() => {
    if (user.roles.includes("admin") && inviteList) {
      let inv_arr = inviteList.data.filter(
        (ele) => ele.parentId === user.id && ele.status === "completed"
      );
      if (inv_arr.length > 0) {
        setShowAdminBtn(true);
      }
    }
    setMainData(inviteList);
  }, [inviteList]);

  React.useEffect(() => {
    if (data?.success) {
      handleClose();
    }
  }, [data, isSuccess]);

  useEffect(() => {
    setUserRole(user.roles[0]);
    if (user.roles.includes("owner")) {
      setShowAction(true);
    }
  }, [user]);

  const onChange = (key, value) => {
    setInviteData({ ...inviteData, [key]: value });
  };
  const handleBlockEvents = async (blockStatus, id) => {
    const data = { userId: id, blocked: blockStatus };
    await userService.updateUser(data);
    let inviteData = await fetchInvites();
    inviteList = inviteData;
    setMainData(inviteData);
  };

  const handleUsersEvents = async (userStatus, id) => {
    // console.log("handleUsersEvents", userStatus, id);
    let newRoles = [];

    if (userStatus === "user") {
      newRoles = ["admin"];
    } else {
      newRoles = ["user"];
    }

    const data = { userId: id, roles: newRoles };
    // console.log("handleUsersEvents 2222", newRoles, data);
    await userService.updateUser(data);
    let inviteData = await fetchInvites();
    inviteList = inviteData;
    setMainData(inviteData);
  };

  const onSubmit = (values) => {
    const businessName = user?.businessName ? user?.businessName : "";
    // console.log("my console for checking data for business name", {
    //   ...inviteData,
    //   businessName,
    // });
    inviteNewUser({ ...inviteData, businessName });
  };

  // This function will resend email to sub child of owner
  const reSendInvite = async (email, role) => {
    try {
      const businessName = user?.businessName ? user?.businessName : "";
      setInviteData({
        email: email,
        role: role,
        businessName: businessName,
      });
      setLoader(true);
      // console.log("my console for reSendInvite", { businessName, email, role });
      await inviteNewUser({ businessName, email, role });
      openNotificationWithIcon("success", "Successfully Invited");
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    } catch (error) {
      console.log("error  while resending email", error);
      setLoader(false);
    }
  };

  const handleResetPassword = (inviteEmail) => {
    // console.log("My console for inviteEmail to reset password", inviteEmail);
    resetPasswordHandler({ email: inviteEmail });
    openNotificationWithIcon(
      "success",
      "Success",
      "Email with link to reset password sent successfully."
    );
  };
  const deleteConfirmModal = () => {
    setDeleteModal(true);
  };

  /**
   *  Function to check if the user click one checkbox manually then disabled pending jobs user
   * @params : data (Type: Array) email,userinfo,
   * @return : data (Type: Array)
   * @author : Nafees
   **/
  const handleCheckboxChange = async (email, userInfo) => {
    // console.log("print selected email: ", email, userInfo, user.email);

    if (!userInfo || !userInfo.id) {
      const updatedSelectDeleteRows = [...selectDeleteRows];
      const emailIndex = updatedSelectDeleteRows.indexOf(email);

      if (emailIndex !== -1) {
        // Email is currently selected, unselect it
        updatedSelectDeleteRows.splice(emailIndex, 1);
      } else {
        // Email is not selected, select it
        updatedSelectDeleteRows.push(email);
      }

      setSelectDeleteRows(updatedSelectDeleteRows);

      // Check if selectDeleteRows is empty and set deleteButton accordingly
      if (updatedSelectDeleteRows.length === 0) {
        setDeleteButton(false);
      } else {
        setDeleteButton(true);
      }

      // No need to update the disabled state when userInfo is undefined
      return;
    }

    // If userInfo is defined, check for pending jobs and update disabled state as before
    const hasPendingJobs = await hasPendingJobsForUser(userInfo.id);
    const updatedDisabledState = { ...isCheckboxDisabled };

    if (!hasPendingJobs) {
      let updatedSelectDeleteRows = [...selectDeleteRows];
      // console.log(
      //   "updatedSelectDeleteRows: ",
      //   updatedSelectDeleteRows,
      //   isCheckboxDisabled
      // );

      if (updatedSelectDeleteRows.includes(email)) {
        updatedSelectDeleteRows = updatedSelectDeleteRows.filter(
          (selectedEmail) => selectedEmail !== email
        );
        // console.log(
        //   "inside updatedSelectDeleteRows: ",
        //   updatedSelectDeleteRows
        // );
      } else {
        updatedSelectDeleteRows.push(email);
      }

      updatedDisabledState[email] = false;
      setSelectDeleteRows(updatedSelectDeleteRows);

      // Check if selectDeleteRows is empty and set deleteButton accordingly
      if (updatedSelectDeleteRows.length === 0) {
        setDeleteButton(false);
      } else {
        setDeleteButton(true);
      }
    } else {
      updatedDisabledState[email] = true;
    }

    setIsCheckboxDisabled(updatedDisabledState);
  };
  /**
   *  Function to check if the user has pending jobs
   * @params : data (Type: Array),
   * @return : data (Type: Array)
   * @author : Nafees
   **/

  const hasPendingJobsForUser = async (userId) => {
    let pendingJobs;
    let retrieveCust = await CustomerApi.retrieveCustomerByParams({
      user: userId,
    });
    const customerData = retrieveCust[0];
    pendingJobs = await JobApi.latestJobForCustomer({
      customer: customerData.id,
    });

    if (pendingJobs.length > 0) {
      const hasInProgressJob = pendingJobs.some((job) => {
        return (
          job?.status === "Inprogress" ||
          job?.status === "long-job" ||
          job?.status === "Accepted" ||
          job?.status === "Pending" ||
          job?.status === "Scheduled" ||
          job?.status === "Waiting" ||
          job?.schedule_accepted
        );
      });
      // console.log("hasInProgressJob", hasInProgressJob);
      setIsCheckboxDisabled(hasInProgressJob);
      return hasInProgressJob;
    } else {
      setIsCheckboxDisabled(false);
      return false;
    }
  };
  /**
   * Following function used to select all invite user expect pending jobs
   * @author : Nafees
   **/
  const handleSelectAll = async () => {
    // Toggle the selectAll state
    setSelectAll(!selectAll);
    setIsLoadingSelect(true);
    if (selectAll) {
      // Deselect all rows and clear the disabled state
      setSelectDeleteRows([]);
      setIsCheckboxDisabled({});
      setDeleteButton(false);
      setIsLoadingSelect(false);
    } else {
      const filteredEmails = await filterEmails(invites);

      setSelectDeleteRows(filteredEmails);
      setIsLoadingSelect(false);

      // console.log("selectAll", filteredEmails);

      if (filteredEmails.length === 0) {
        setDeleteButton(false);
      } else {
        setDeleteButton(true);
      }
    }
  };
  /**
   * Following function fileter pending jobs user
   * @params : data (Type: Array),
   * @return : data (Type: Array)
   * @author : Nafees
   **/
  const filterEmails = async (invites) => {
    const filteredEmails = await Promise.all(
      invites
        .filter(
          (invite) => user.email !== invite.email && invite.status !== "deleted"
        )
        .map(async (invite) => {
          const email = invite.email;
          const userInfo = invite?.userId;

          if (userInfo && userInfo.id) {
            // Check for pending jobs
            const hasPendingJobs = await hasPendingJobsForUser(userInfo.id);

            // If there are pending jobs, return null to remove the email from the array
            if (hasPendingJobs) {
              return null;
            }
          }

          // If userInfo is undefined or there are no pending jobs, return the email
          return email;
        })
    );

    // Filter out null values (emails with pending jobs)
    const finalEmails = filteredEmails.filter((email) => email !== null);

    return finalEmails;
  };

  // useEffect(() => {
    // console.log("print update value ", selectDeleteRows, disableDeleteCheck);
    // if(selectDeleteRows>0){
    //   setDeleteButton(true);
    // }
  // }, [selectDeleteRows]);

  // if (isLoadingSelect) {
  //   return (
  //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',width:'100%' }}>
  //       <Loader height="100%" className={(isLoadingSelect ? "loader-outer" : "d-none")}/>
  //     </div>
  //   );
  // }
  return (
    <>
      <InviteDeleteUserModal
        setDeleteModal={setDeleteModal}
        deleteModal={deleteModal}
        selectDeleteRows={selectDeleteRows}
        setRefreshInvitesList={setRefreshInvitesList}
        setDeleteButton={setDeleteButton}
        setSelectAll={setSelectAll}
      />

      <ImportCSVModal
        openImportCSVModal={openImportCSVModal}
        setOpenImportCSVModal={setOpenImportCSVModal}
        user={user}
        setRefreshInvites={setRefreshInvites}
        setPriorOrExistedUser={setPriorOrExistedUser}
      />

      <Row className="col-md-12">
        <Col xs="12" className="">
          {data?.success && (
            <Alert variant="success" className="mt-3" style={{ width: "50%", margin: "auto" }}>
              {data.message}
            </Alert>
          )}
          <Col
            xs="12"
            className="pt-5 pb-3 d-flex justify-content-between align-items-center"
          >
            <h1 className="large-heading col-md-4">Users List</h1>
            <div className="row btns-style col-md-8 justify-content-end">
              <Button
                onClick={() => setOpenImportCSVModal(true)}
                className="btn app-btn btn btn-primary import-csv-main-btn-style m-1"
              >
                Import CSV
              </Button>
              <Button
                onClick={() => setShow(true)}
                className="btn app-btn btn btn-primary mr-1"
              >
                Invite
              </Button>
              {deleteButton && (
                <Button
                  onClick={() => deleteConfirmModal()}
                  className="btn app-btn btn btn-danger mr-1"
                  style={{ backgroundColor: "#C82333" }}
                >
                  {invites.filter((invite) => invite.status !== "deleted")
                    .length === 1 || selectDeleteRows.length === 1
                    ? "Delete User"
                    : "Delete Users"}
                </Button>
              )}
            </div>
          </Col>

          {priorOrExistedUSer &&
          priorOrExistedUSer.showAlert &&
          priorOrExistedUSer?.list?.length > 0 ? (
            <PriorOrExistedUser priorOrExistedUSer={priorOrExistedUSer.list} />
          ) : null}

          <Col md="12" className="py-4 mt-1 table-responsive">
            <Col xs="12" className="table-structure-outer table-responsive">
              <table className="w-100">
                <thead>
                  <tr>
                    <th className="label-name">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                        className="userCheckbox mr-2"
                      />
                      Email
                    </th>
                    <th className="label-name">Status</th>

                    <th className="label-name">Invited Date</th>
                    <th className="label-name">Role</th>
                    <th className="label-name">Action</th>
                  </tr>
                </thead>
                <Loader
                  height="100%"
                  className={isLoadingSelect ? "loader-outer" : "d-none"}
                />
                <tbody>
                  <tr className="table-primary">
                    <td className="cell-value">
                      {user.roles.indexOf(roleStatus.OWNER) > -1
                        ? user.email
                        : mainData?.parent?.email}
                    </td>
                    <td className="cell-value">Completed</td>
                    <td className="cell-value">N/A</td>
                    <td className="cell-value">owner</td>
                    <td className="cell-value"></td>
                  </tr>
                  {invites?.length > 0 ? (
                    invites.map((invite, i) =>
                      invite.status === "deleted" ? null : (
                        <tr
                          className={
                            user.email === invite.email ? "table-active" : ""
                          }
                          key={i}
                        >
                          <td className="cell-value">
                            {user.email === invite.email ? null : (
                              <input
                                type="checkbox"
                                className="userCheckbox mr-2"
                                onChange={() =>
                                  handleCheckboxChange(
                                    invite.email,
                                    invite?.userId
                                  )
                                }
                                checked={selectDeleteRows.includes(
                                  invite.email
                                )}
                                disabled={isCheckboxDisabled[invite.email]}
                                title={
                                  isCheckboxDisabled[invite.email]
                                    ? "Not deletable due to pending jobs"
                                    : ""
                                }
                              />
                            )}
                            {user.email === invite.email
                              ? `${invite.email} (You)`
                              : invite.email}
                          </td>

                          <td
                            className="cell-value"
                            style={{ textTransform: "capitalize" }}
                          >
                            {invite.status}
                          </td>
                          <td className="cell-value">
                            {moment(invite.createdAt).format(
                              "MM/DD/yyyy, H:mm a"
                            )}
                          </td>

                          <td className="cell-value">{invite.role}</td>
                          {(showAction ||
                            userRole == "admin" ||
                            userRole == "owner") &&
                          user.email != invite.email &&
                          invite.status == "completed" ? (
                            <>
                              <td className="cell-value">
                                {userRole == "owner" && (
                                  <Button
                                    title={
                                      invite.role === "admin"
                                        ? "Change Role To User"
                                        : "Change Role To Admin"
                                    }
                                    style={{
                                      backgroundColor: "#01D4D5",
                                      border: "none",
                                      fontWeight: "600",
                                    }}
                                    onClick={() => {
                                      handleUsersEvents(
                                        invite.role,
                                        invite.userId.id
                                      );
                                    }}
                                    className="btn btn-success app-btn-small mr-2"
                                  >
                                    Change User Role
                                  </Button>
                                )}

                                {invite.userId && invite.userId.blocked ? (
                                  <Button
                                    onClick={() => {
                                      handleBlockEvents(
                                        false,
                                        invite.userId.id
                                      );
                                    }}
                                    className="btn btn-success app-btn-small mr-2 mobile_responsive"
                                  >
                                    Unblock
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      handleBlockEvents(true, invite.userId.id);
                                    }}
                                    className="btn btn-danger app-btn-small mr-2 mobile_responsive"
                                  >
                                    Block
                                  </Button>
                                )}

                                <Button
                                  onClick={() =>
                                    handleResetPassword(invite.email)
                                  }
                                  className="btn btn-warning app-btn-small"
                                >
                                  Reset Password
                                </Button>
                              </td>
                            </>
                          ) : (
                            <>
                              {invite.status === "pending" ? (
                                <td className="cell-value">
                                  <Button
                                    onClick={() =>
                                      reSendInvite(invite.email, invite.role)
                                    }
                                    disabled={
                                      loader &&
                                      invite.email === inviteData.email
                                    }
                                    className={`btn btn-success app-btn-small ${
                                      loader &&
                                      invite.email === inviteData.email
                                        ? "resend-email-style-blocked"
                                        : "resend-email-style"
                                    }`}
                                  >
                                    {loader &&
                                    invite.email === inviteData.email ? (
                                      <Spin />
                                    ) : (
                                      "Resend Invite"
                                    )}
                                  </Button>
                                </td>
                              ) : (
                                <td></td>
                              )}
                            </>
                          )}
                        </tr>
                      )
                    )
                  ) : (
                    <>
                      {user.roles.indexOf(roleStatus.OWNER) > -1 ? (
                        <></>
                      ) : (
                        <tr>
                          <td colspan="5" align="middle">
                            <Box>
                              <div className="divarea">
                                <p>No data available.</p>
                              </div>
                            </Box>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </Col>
          </Col>
        </Col>
      </Row>
      <Modal show={showInviteModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Invite New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data && !data.success && (
            <Alert variant="danger" className="w-100">
              {data.message}
            </Alert>
          )}
          <Form className="items-center" onFinish={onSubmit}>
            <FormItem
              name="email"
              rules={[
                {
                  // type: "email",
                  type: "regexp",
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail.",
                },
              ]}
            >
              <Input
                name="email"
                size="large"
                placeholder="Email"
                className="email-login-class px-3"
                onChange={(e) => onChange("email", e.target.value)}
              />
            </FormItem>
            <Select
              size="large"
              style={{ width: "100%", borderRadius: 20 }}
              placeholder="Role type"
              defaultValue={inviteData.role}
              onChange={(value) => onChange("role", value)}
            >
              <Option value="user">User</Option>
              {!user.roles ||
                (user?.roles.indexOf(roleStatus.ADMIN) === -1 && (
                  <Option value="admin">Admin</Option>
                ))}
            </Select>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={false}
              className="btn app-btn mt-3"
              disabled={!!isLoading}
            >
              <span />
              {isLoading ? <Spin /> : "Send Invite"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Invite;
