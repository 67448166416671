import BasicButton from "components/common/Button/BasicButton";
import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button } from "react-bootstrap";
import HeadingText from "../Components/HeadingText";
import InputField from "../Components/InputField";
import SubHeadingText from "../Components/SubHeadeingText";
import McAfeeLogo from "../../../../assets/images/McAfee.png";
import stripeLogo from "../../../../assets/images/stripe.png";
import psbaLogo from "../../../../assets/images/pbsa.png";
import AGSlogo from "../../../../assets/images/AGSlogo.png";
import ChosenPlanSummary from "../Components/ChosenPlanSummary";
import Testimony from "../Components/Testimony";
import {
  openNotificationWithIcon,
  checkPendingStatus,
  checkAndHandleDateMatchForSchedule,
  fetchChatOrCreate,
  isLiveUser,
  PushUserDataToGtm,
  setCookie
} from "utils";
import * as UserApi from "../../../../api/users.api";
import * as AuthApi from "../../../../api/auth.api";
import { useAuth } from "../../../../context/authContext";
import { useHistory, useLocation } from "react-router";
import {
  emailRegExp,
  INACTIVE_ACCOUNT_STATUS_MSG,
  SSO_MSG_FOR_INCORRECT_LOGIN,
  JobTags,
  APEX_ORG_NAME
} from "../../../../constants/index";
import JobInfoRightSideBar from "components/JobInfoRightSideBar";
import Loader from "../../../../components/Loader";
import { useFetchInvite } from "../../../../api/invite.api";
import googleIcon from "../../../../assets/images/google.svg";
import microsoftIcon from "../../../../assets/images/microsoft.png";
import { useGoogleLogin } from "@react-oauth/google";
import { useUser } from "context/useContext";
// import { checkCustomerHaveCard } from "utils/jobPosting";
import { useSocket } from "context/socketContext";
import { useNotifications } from "context/notificationContext";
import * as JobApi from "../../../../api/job.api";
import { useJob } from "../../../../context/jobContext";
import { Modal, Spin } from "antd";
import * as CustomerApi from "../../../../api/customers.api";
import * as JobCycleApi from "../../../../api/jobCycle.api";
import Header from "../Components/Header";

import mixpanel from "mixpanel-browser";

const SignIn = ({
  setbusinessPlanStepNumber,
  jobFlowStepsObj,
  setUserInfo,
  setGivenEmail,
  setchosenProdId,
  setShowSubscriptionPlanModal,
  setShowtwentyPercentModal,
  user,
  setToken,
  job,
}) => {
  localStorage.removeItem('firstFreePromoCode')
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const planId = queryParams.get("planId") ? queryParams.get("planId") : false;
  const jobIdFromUrl = queryParams.get("jobId")
    ? queryParams.get("jobId")
    : false;
  const technicianId = queryParams.get("technicianId")
    ? queryParams.get("technicianId")
    : false;
  const couponCode = queryParams.get("couponCode")
    ? queryParams.get("couponCode")
    : false;
  // let liveUser = isLiveUser(user)
  const inviteCode = queryParams.get("inviteCode") || "nothing";
  const { data: inviteData } = useFetchInvite(inviteCode);
  const [inputEmail, setinputEmail] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const { getGuestUser, setSSOSignupUser } = useAuth();
  const [showSubscriptionPlanModalTime, setShowSubscriptionPlanModalTime] =
    useState(150000);
  const [discountModalShown, setDiscountModalShown] = useState(false);
  const [errorInput, setErrorInput] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [codeResponse, setCodeResponse] = useState();
  const { createNotification, fetchNotifications } = useNotifications();
  const { setUser } = useUser();
  const history = useHistory();
  const { socket } = useSocket();
  const [customerValid, setCustomerValid] = useState(false);
  const [loginUser, setLoginUser] = useState();
  const [latestPendingJobToUpdate, setLatestPendingJobToUpdate] = useState();
  const [scheduleMsg, setScheduleMsg] = useState(false);
  const [jobIdDecline, setJobIdDecline] = useState(false);
  const [lastPendingSoftware, setLastPendingSoftware] = useState("");
  const [pendingJobModal, setPendingJobModal] = useState(false);
  const [inProgressMessage, setInProgressMessage] = useState("");
  const [customerConfirm, setCustomerConfirm] = useState(false);
  const { updateJob } = useJob();
  const [createJobStatus, setCreateJobStatus] = useState(false);
  const org_id = queryParams.get("org");
  const [orgAvailableInUrl, setOrgAvailableInUrl] = useState(false);

  const InprogressJobMsg = pendingJobModal ? (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      {inProgressMessage}
    </span>
  ) : (
    <span></span>
  );

  const message = scheduleMsg ? (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      One of your previous job of{" "}
      <b style={{ fontWeight: "bold" }}>{lastPendingSoftware}</b> is already
      scheduled with a technician. Are you sure you want to create a new job
      post?if yes, then your previous job will be{" "}
      <b style={{ fontWeight: "bold" }}>Cancelled</b>
    </span>
  ) : (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      We are still looking for a technician for your existing job of{" "}
      <b style={{ fontWeight: "bold" }}>{lastPendingSoftware}</b>. Are you sure
      you want to create a new job post? if yes, then your previous job will be{" "}
      <b style={{ fontWeight: "bold" }}>Cancelled</b>
    </span>
  );
  useEffect(() => {
    if (inviteCode === "nothing") setIsLoading(false);
    if (org_id && org_id === APEX_ORG_NAME) setOrgAvailableInUrl(true);
  }, []);

  useEffect(() => {
    if (!user) {
      if (process.env.REACT_APP_URL) {
        const appUrl = process.env?.REACT_APP_URL?.split("/")[2] || false;
        PushUserDataToGtm("b2b_signin", user, appUrl);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!discountModalShown && planId) {
      setTimeout(() => {
        setShowtwentyPercentModal(true);
        setDiscountModalShown(true);
      }, showSubscriptionPlanModalTime);
    }
  }, [showSubscriptionPlanModalTime]);

  useEffect(() => {
    if (inviteData) {
      if (inviteData.status === "completed") {
        window.location.href = "/";
      } else {
        setIsLoading(false);
      }
    }
  }, [inviteData]);

  /**
   * Following function will handle email input field change
   * @params = event
   * @response : null
   * @author : Vinit
   */
  const emailInputOnChange = (e) => {
    setErrorInput(false);
    setinputEmail(e.target.value.trim());
    setShowSubscriptionPlanModalTime(150000);
  };

  /**
   * Following function will check if user entered valid email or not
   * @params = null
   * @response : null
   * @author : Vinit
   */
  const emailValidation = () => {
    if (!emailRegExp.test(String(inputEmail))) {
      return false;
    } else {
      setGivenEmail(inputEmail);
      return true;
    }
  };

  /**
   * Following function will handle whether to ask user to sign-up or log-in
   * @params = null
   * @response : null
   * @author : Vinit
   */
  const handleBtnClick = async (e) => {
    e.preventDefault();
    setShowSubscriptionPlanModalTime(150000);
    setDisableBtn(true);
    if (inputEmail === "") {
      openNotificationWithIcon("info", "info", `Please enter your email!`);
      setDisableBtn(false);
      setErrorInput(true);
      return;
    }
    const isValidEmail = emailValidation();
    if (isValidEmail) {
      setErrorInput(false);
      const guestUserRes = await getGuestUser();
      const user = await UserApi.getUserByParamAsGuest(
        { email: inputEmail.toLowerCase() },
        guestUserRes.token.accessToken
      );

      if (user) {
        if (user.userType === "customer") {
          setUserInfo(user);
          setbusinessPlanStepNumber(jobFlowStepsObj["LogIn"]);
        } else if (user.userType === "technician") {
          openNotificationWithIcon(
            "error",
            "Error",
            `This email has already been used for a technician account. Please use another email to sign up as a customer.`
          );
          setDisableBtn(false);
          setErrorInput(true);
        } else {
          window.location.href = "/";
        }
      } else {
        setbusinessPlanStepNumber(jobFlowStepsObj["ChooseYourPassWord"]);
      }
    } else {
      setErrorInput(true);
      openNotificationWithIcon("info", "info", `Please check email format!`);
      setDisableBtn(false);
    }
  };

  const loginWithGoogle = useCallback(
    useGoogleLogin({
      onSuccess: async (codeResponse) => {
        takeNextStepAction(codeResponse, "customer");
      },
      onError: (errorResponse) => console.log('loginWithGoogle', errorResponse),
    }),
    []
  );

  const handleMicrosoftLogin = async () => {
    try {
      setDisableBtn(true);
      const script = document.createElement('script');
      script.src = 'https://alcdn.msauth.net/browser/2.30.0/js/msal-browser.min.js';
      script.async = true;
      await new Promise((resolve, reject) => {
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });

      const msalConfig = {
        auth: {
          clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
          authority: "https://login.microsoftonline.com/common",
          redirectUri: window.location.origin,
        },
        cache: {
          cacheLocation: "sessionStorage",
          storeAuthStateInCookie: false
        }
      };

      const msalInstance = new window.msal.PublicClientApplication(msalConfig);
      const loginRequest = { scopes: ["user.read", "profile", "email", "openid"], prompt: 'select_account' };
      const msalResponse = await msalInstance.loginPopup(loginRequest);

      const tokenResponse = await msalInstance.acquireTokenSilent({
        scopes: ["user.read"],
        account: msalResponse.account
      }).catch(async (error) => {
        if (error.name === "InteractionRequiredAuthError") {
          return await msalInstance.acquireTokenPopup({
            scopes: ["user.read"],
            prompt: 'select_account'
          });
        }
        throw error;
      });

      const response = await AuthApi.loginMicrosoft({
        accessToken: tokenResponse.accessToken,
        userType: user?.userType || "customer"
      });

      if (response) {
        const { user, token, registered, error } = response;
        if (error && !response.success) {
          openNotificationWithIcon("error", "Error", error);
        }
        if (user && (token === null) & !registered) {
          setSSOSignupUser(user);
          sessionStorage.setItem("SSOSignupUser", JSON.stringify(user))
          setGivenEmail(user?.email);
          setbusinessPlanStepNumber(jobFlowStepsObj["ChooseYourPassWord"]);
        } else {
          if (user && user?.userType === "customer") {
            openNotificationWithIcon("success", "Success", "User logged in successfully!");
            setToken(token.accessToken);
            setCookie(user);
            if (jobIdFromUrl) {
              const customerTypeValid = user.customer.customerType
                ? user.customer.customerType !== "test"
                : true;
              setCustomerValid(customerTypeValid);
              checkPendingJobs(user, customerTypeValid);
              setLoginUser(user);
            } else {
              if (planId) {
                if (couponCode) {
                  window.location.href = `/buy-business-plan?planId=${planId}&page=CompleteYourPurchase&couponCode=${couponCode}`;
                } else {
                  window.location.href = `/buy-business-plan?planId=${planId}&page=CompleteYourPurchase`;
                }
              } else {
                if (user) {
                  let liveUser = await isLiveUser(user);
                  let customer_info =
                    await CustomerApi.checkIfOrganisationHasSubscription({
                      user: user,
                      liveUser: liveUser,
                    });

                  if (customer_info.has_card_or_subscription) {
                    window.location.href = `/`;
                  } else {
                    window.location.href = `/customer/registered`;
                  }
                }
              }
            }
          }
        }
      }

    } catch (error) {
      console.error("Microsoft login error:", error);
    } finally {
      setDisableBtn(false);
    }
  };

  const takeNextStepAction = async (data, userType) => {
    try {
      const res = await AuthApi.loginGoogle({
        accessToken: data.access_token,
        userType,
      });

      if (res) {
        const { user, token, registered, error } = res;
        if (error && !res.success) {
          openNotificationWithIcon("error", "Error", error);
        }
        if (user && (token === null) & !registered) {
          setSSOSignupUser(user);
          sessionStorage.setItem("SSOSignupUser", JSON.stringify(user))
          setGivenEmail(user?.email);
          setbusinessPlanStepNumber(jobFlowStepsObj["ChooseYourPassWord"]);
        } else if (user && user?.userType == "technician") {
          openNotificationWithIcon(
            "error",
            "Account Registration Error",
            SSO_MSG_FOR_INCORRECT_LOGIN
          );
          return;
        } else {
          if (user && user?.userType === "customer") {

            openNotificationWithIcon("success", "Success", "User logged in successfully!");
            setToken(token.accessToken);
            setCookie(user);
            if (jobIdFromUrl) {
              const customerTypeValid = user.customer.customerType
                ? user.customer.customerType !== "test"
                : true;
              setCustomerValid(customerTypeValid);
              checkPendingJobs(user, customerTypeValid);
              setLoginUser(user);
            } else {

              if (planId) {
                if (couponCode) {
                  window.location.href = `/buy-business-plan?planId=${planId}&page=CompleteYourPurchase&couponCode=${couponCode}`;
                } else {
                  window.location.href = `/buy-business-plan?planId=${planId}&page=CompleteYourPurchase`;
                }
              } else {

                //Checking if customer already have card added to account.
                if (user) {
                  let liveUser = await isLiveUser(user);
                  let customer_info =
                    await CustomerApi.checkIfOrganisationHasSubscription({
                      user: user,
                      liveUser: liveUser,
                    });

                  if (customer_info.has_card_or_subscription) {
                    window.location.href = `/`;
                  } else {
                    window.location.href = `/customer/registered`;
                  }
                }
              }
            }
          }

          // setUser(user)
          // setToken(token.accessToken);
          // const customerTypeValid = (user?.customer?.customerType ? user?.customer?.customerType !== 'test' : true);
          // await checkCustomerHaveCard(user,customerTypeValid,jobIdFromUrl,job,history,socket,technicianId,createNotification,fetchNotifications)
        }
      }
    } catch (error) {
      console.log("Error while SSO Login", error);
      openNotificationWithIcon("Something went wrong");
    }
  };

  /**
   * Function will check if there are any pending jobs of the customer else it will call push_to_profile_setup function to find technician.
   * @author : Nafees
   */
  const checkPendingJobs = async (loginUser, customerTypeValid) => {
    try {

      if (loginUser && loginUser?.customer) {
        const latestpendingJobs = await JobApi.latestpendingJobs({
          customer: loginUser.customer.id,
        });

        setLatestPendingJobToUpdate(latestpendingJobs);

        let pendingJobs = await checkPendingStatus(loginUser);

        if (pendingJobs.schedule_accepted) {
          setScheduleMsg(true);
        }
        setJobIdDecline(latestpendingJobs.last_pending_job.id);
        if (pendingJobs.success) {
          setLastPendingSoftware(pendingJobs.name);
          if (
            pendingJobs.status === "Inprogress" ||
            pendingJobs.status === "Accepted"
          ) {
            setPendingJobModal(true);
            setInProgressMessage(
              <span
                className="div-font"
                style={{ fontSize: 20, paddingTop: "40px" }}
              >
                One of your job of{" "}
                <b style={{ fontWeight: "bold" }}>{pendingJobs.name}</b> is
                already{" "}
                <b style={{ fontWeight: "bold" }}>{pendingJobs.status}</b> with
                a technician. Please complete that job first then post a new
                job.
              </span>
            );
          } else {
            setCustomerConfirm(true);
          }
        } else {
          checkCustomerHaveCard(loginUser, customerTypeValid);
        }
      }
    } catch (e) {
      console.log("Error in checkPendingJobs ", e);
    }
  };

  const checkCustomerHaveCard = async (user, customerTypeValid) => {
    try {
      if (user && !user?.activeStatus) {
        openNotificationWithIcon("info", "Info", INACTIVE_ACCOUNT_STATUS_MSG);
        const timer = setTimeout(() => {
          history.push("/dashboard");
        }, 2000);
        return () => clearTimeout(timer);
      }
      if (user && user.customer) {
        mixpanel.identify(user.email);
        const ownerId = user?.ownerId;

        let ownerStripeId = "";
        if (ownerId) {
          const ownerUserDetails = await UserApi.getUserById(ownerId);

          if (ownerUserDetails?.customer?.stripe_id) {
            ownerStripeId = ownerUserDetails?.customer?.stripe_id;
          } else {
            openNotificationWithIcon(
              "info",
              "Info",
              `Please contact your organization owner to add card to proceed with this job!`
            );
            history.push("/");
            return;
          }
        }

        const data = await JobApi.scheduleAcceptedJobs({
          customer: user?.customer?.id,
        });
        const primaryScheduleTimes = data.primaryScheduleTimes;

        // Call the function to check and handle date match
        checkAndHandleDateMatchForSchedule(
          primaryScheduleTimes,
          job?.primarySchedule,
          jobIdFromUrl,
          user
        );

        let customer_info =
          await CustomerApi.checkIfOrganisationHasSubscription({
            user: user,
            liveUser: user.customer.customerType === "live",
          });
        if (
          customer_info.has_card_or_subscription === false &&
          customerTypeValid
        ) {
          // mixpanel code//
          mixpanel.track("Customer - Ask Credit Card");
          mixpanel.people.set({
            $first_name: user.firstName,
            $last_name: user.lastName,
          });
          // mixpanel code//
          const updatedJOb = await updateJob(jobIdFromUrl, {
            customer: user.customer.id,
            guestJob: false,
            cardPreAuthorization: false,
          });

          window.location.href = `/customer/profile-setup?page=add-card&jobId=${jobIdFromUrl}`;
        } else {
          let paidJobs = await JobApi.getTotalJobs({
            customer: user?.customer?.id,
          });
          if (paidJobs >= 1) {
            await JobApi.updateJob(jobIdFromUrl, { isReturningCustomer: true });
          }
          const stripeId = user?.ownerId
            ? ownerStripeId
            : user?.customer?.stripe_id;
          let preauthorize = await CustomerApi.holdChargeFromCustomer({
            stripe_id: stripeId,
            liveUser:
              user?.customer?.customerType &&
                user?.customer?.customerType === "live"
                ? true
                : false,
            jobId: jobIdFromUrl,
          });

          if (preauthorize.status === "Successful") {
            await JobCycleApi.create(JobTags.HOLD_CHARGE, jobIdFromUrl);
            mixpanel.track("Customer - Preauthorize successfully", {
              jobId: jobIdFromUrl,
            });
            if (localStorage.getItem("isScheduleJob") === true || localStorage.getItem("isScheduleJob") === 'true') {
              localStorage.removeItem("isScheduleJob");
              console.log("inside preauthorize schedule if");
              const updatedJob = await JobApi.updateJob(jobIdFromUrl, {
                status: "Scheduled",
                customer: user.customer.id,
                guestJob: false,
                cardPreAuthorization: true,
              });
              // createUpdateJob("Scheduled")
              if (updatedJob && user) {
                await fetchChatOrCreate(updatedJob, user);
              }
              await emitSocketCreateFetchNotification(updatedJob, user);

              setTimeout(() => {
                window.location.href =
                  "/dashboard?&scheduleJobId=" + jobIdFromUrl;
              }, 500);
              setDisableBtn(false);
            } else {
              console.log("inside preauthorize ASAP else");
              try {
                const testingRes = await JobApi.updateJob(jobIdFromUrl, {
                  status: "Pending",
                  customer: user.customer.id,
                  guestJob: false,
                  cardPreAuthorization: true,
                  tech_search_start_at: new Date(),
                });
                if (testingRes && user) {
                  await fetchChatOrCreate(testingRes, user);
                }

                window.location.href = `/customer/profile-setup?page=tech-search&jobId=${jobIdFromUrl}`;
              } catch (error) {
                console.error("Error:", error);
              }
            }
          } else {
            await JobCycleApi.create(JobTags.HOLD_CHARGE_FAIL, jobIdFromUrl);
            let mixpanelData = {};
            if (job && job.id) {
              mixpanelData = { jobId: job.id };
            }
            mixpanel.track("Customer - Preauthorize Failed", mixpanelData);
            if (job && job.id && job.guestJob && user && user.customer) {
              await updateJob(job.id, {
                customer: user.customer.id,
                guestJob: false,
              });
            }
            let timeOutDuration = 2000;
            if (
              preauthorize?.response?.decline_code === "invalid_account" ||
              preauthorize?.message.includes("Invalid account")
            ) {
              timeOutDuration = 4000;
              openNotificationWithIcon(
                "error",
                "Error",
                "Unable to authorize your existing credit card. Please add new credit card from Settings -> Card Details."
              );
            } else {
              openNotificationWithIcon("error", "Error", preauthorize.message);
            }

            setTimeout(() => {
              window.location.href = "/dashboard";
            }, timeOutDuration);
          }
          // }
        }
      }
    } catch (err) {
      console.log("error in checkForCard", err);
    }
  };

  const emitSocketCreateFetchNotification = async (jobStats, user) => {
    try {
      console.log("send-schedule-alerts :::::::::::");
      //Notification for customer
      const notificationData = {
        user: user.id,
        job: jobStats.id,
        read: false,
        actionable: false,
        title:
          "We are finding a technician for you. We will inform you when we find the technician",
        type: "Scheduled Job",
      };
      // console.log("notificationData ::::::::", notificationData);
      await createNotification(notificationData);
      console.log("fetchNotifications() :: called emitSocketCreateFetchNotification function in signin.ts");
      await fetchNotifications({ user: user.id });

      // call send-schedule-alerts socket from backend.
      // It will find available techs and send alerts by sms/email/notification
      socket.emit("search-for-tech", {
        jobId: jobStats.id,
        customerTimezone: user.timezone,
        jobData: jobStats,
        primaryTime: jobStats.primarySchedule,
        phoneNumber: user.customer.phoneNumber,
        customerName: user.firstName,
        customerEmail: user.email,
        technicianId: technicianId ? technicianId : false,
      });
    } catch (err) {
      console.log(
        "There is catch error while create/fetch notification  :::: " +
        err.message
      );
      mixpanel.identify(user.email);
      mixpanel.track("There is catch error while create/fetch notification", {
        jobStats: jobStats,
        errMessage: err.message,
      });
    }
  };

  const closePendingModal = () => {
    setCustomerConfirm(false);
    setPendingJobModal(false);
  };
  const push_to_job_summary = async () => {
    setCreateJobStatus(true);
    await updateJob(jobIdDecline, { status: "Declined" });
    checkCustomerHaveCard(loginUser, customerValid);
    // const updateJobData = {
    //     cardPreAuthorization: false,
    //     customer: loginUser?.customer?.id,
    //     guestJob:false
    //   };
    // const updateJobCurrent = await updateJob(jobIdFromUrl, updateJobData)
    // window.location.href = `/customer/profile-setup?page=job-summary&jobId=${jobIdFromUrl}`;
  };

  const backToDashBoardStatus = async () => {
    const updateJobData = {
      cardPreAuthorization: false,
      customer: loginUser?.customer?.id,
      guestJob: false,
    };
    await updateJob(jobIdFromUrl, updateJobData);
    window.location.href = "/dashboard";
  };

  const backToDashBoard = async () => {
    const updateJobData = {
      cardPreAuthorization: false,
      customer: loginUser?.customer?.id,
      guestJob: false,
    };
    await updateJob(jobIdFromUrl, updateJobData);
    window.location.href = "/dashboard";
  };
  if (isLoading) return <Loader height="500px" />;

  return (
    <>
      <Modal
        className="get-help-now-modal"
        closable={true}
        onCancel={closePendingModal}
        visible={pendingJobModal}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        footer={[
          <div className="modal-flex-get-help-now">
            <Button
              className="btn app-btn app-btn-light-blue modal-footer-btn"
              onClick={backToDashBoardStatus}
              key="no"
            >
              <span></span>Back To Dashbord
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {InprogressJobMsg}
          </span>
        </div>
      </Modal>
      <Modal
        className="get-help-now-modal"
        closable={true}
        visible={customerConfirm}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        footer={[
          <div className="modal-flex-get-help-now">
            <Button
              className="btn app-btn app-btn-light-blue modal-footer-btn"
              onClick={backToDashBoard}
              key="no"
            >
              <span></span>Back To Dashbord
            </Button>

            <Button
              id="confirm-create-new"
              className="btn app-btn job-accept-btn modal-footer-btn"
              onClick={push_to_job_summary}
              key="yes"
              disabled={createJobStatus}
            >
              <span></span>Create New
              {createJobStatus && (
                <Spin size="small" style={{ marginLeft: "10px" }} />
              )}
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {message}
          </span>
        </div>
      </Modal>
      <div className="pl-45">
        <Header />
      </div>
      <div className="custom-container d-flex justify-content-center min-height-inherit">

        <Row className="d-flex justify-content-center  align-items-center min-height-inherit w-full parent-row">
          <Col
            md={9}
            className="d-flex flex-column justify-content-center align-items-center min-height-inherit w-full"
          >

            {!orgAvailableInUrl &&
              <>
                <div>
                  <HeadingText firstBlackText={"Alright!"} />
                </div>
                <div className="mb-10 text-center">
                  <HeadingText firstBlackText={"Let's solve this!"} />
                </div>
                <div className="mb-50 text-center">
                  <SubHeadingText
                    text={
                      "Create your account to connect with a friendly Geek expert. ;) "
                    }
                  />
                </div>
              </>
            }
            {orgAvailableInUrl &&
              <>
                <div className="mb-10 text-center">
                  {/* <HeadingText firstBlackText={"AGS Registration"} /> */}
                  <img src={AGSlogo} className="w-50 mb-4" />
                </div>
                <div className="mb-10 text-center">
                  <SubHeadingText
                    text={
                      "Welcome to the AGS + Geeker registration portal."
                    }
                  />
                </div>
                <div className="mb-10 text-center">
                  <span className="subHeading-span-font">
                    Please chat with us or email
                    <a href="mailto:info@geeker.co">
                      <span className="geekerMailInfo"> info@geeker.co </span>
                    </a>
                    with any questions or feedback.
                  </span>
                </div>
                <div className="mb-50 text-center">
                  <span className="subHeading-span-font">
                    <b>Lets get productive!</b>
                  </span>
                </div>
              </>
            }
            <form
              onSubmit={handleBtnClick}
              className="d-flex flex-column gap-20"
            >
              <div className="w-full d-flex justify-content-center ">
                <div className="max-width-768-w-100per">
                  <SubHeadingText text={"Email"} />
                  <InputField
                    id="email-input"
                    onChange={emailInputOnChange}
                    showError={errorInput}
                    placeholder={"abc@domain.com"}
                  />
                </div>
              </div>
              <div className="business-plan-sign-in-button w-full">
                <BasicButton
                  id="continue-btn"
                  btnTitle={"Continue"}
                  height={"inherit"}
                  width={"inherit"}
                  background={"#01D4D5"}
                  color={"white"}
                  btnIcon={"arrow"}
                  faFontSize={"18px"}
                  arrowDirection={"right"}
                  disable={disableBtn}
                  showSpinner={disableBtn}
                  btnType={"submit"}
                  onClick={handleBtnClick}
                />
              </div>

              {!orgAvailableInUrl &&
                <>
                  <button type="button" className="google-btn1" style={{ cursor: "pointer" }} onClick={loginWithGoogle}>
                    <img className="sso_image" src={googleIcon} />
                    <span className="google-btn-inner" >
                      Continue with Google
                    </span>
                  </button>
                  <button
                    type="button"
                    className="google-btn1" style={{ cursor: "pointer" }}
                    onClick={handleMicrosoftLogin}
                    disabled={disableBtn}
                  >
                    <img
                      src={microsoftIcon}
                      alt="Microsoft"
                      className="sso_image"
                    />
                    <span className="google-btn-inner">
                      {disableBtn ? 'Loading...' : 'Continue with Microsoft'}
                    </span>
                  </button>
                </>
              }
              <div className="d-flex max-w-420px justify-content-around w-full">
                <img src={stripeLogo} className="h-41px" />
                <img src={psbaLogo} />
                <img src={McAfeeLogo} />
              </div>
            </form>
          </Col>
          <Col md={3} className="sign-in-side-column">
            {planId && (
              <ChosenPlanSummary
                setchosenProdId={setchosenProdId}
                setShowSubscriptionPlanModal={setShowSubscriptionPlanModal}
              />
            )}
            {jobIdFromUrl && <JobInfoRightSideBar user={user} />}
            <Testimony testimonyBy={"brian"} />
          </Col>
        </Row>
      </div>
    </>

  );
};

export default SignIn;
