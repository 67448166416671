// ProjectCTA.js
import React from 'react';
import { Clock, MessageCircle, ExternalLink } from 'lucide-react';
import styles from './CTA.module.css';
import projectCTAImage from '../../../../assets/images/projectCTA.png';
import { FormatDescription } from './utilsCTA';

const ProjectCTA = ({
  job,
  onJoinMeeting,
  onOpenChat,
  onViewDetails
}) => {
  const software = job?.software || {};
  const technician = job?.technician?.user || {};

  return (
    <div className={styles.CTAContainer}>
      <div className={styles.leftContent}>
        <div className={styles.contentTop}>
          <div className={styles.titleRow}>
            {/* <div className={styles.icon}>
              <img
                className={styles.iconImage}
                alt={software.name || ""}
                src={software.blob_image}
              />
            </div> */}
            <b className={styles.title}>
              <span>{software.name}</span>
              <span className={styles.subtitle}>{` · ${job.subOption || 'Project'}`}</span>
            </b>
          </div>
          <div className={styles.description}>
            {<FormatDescription text={job?.issueDescription} seeMore={onViewDetails} />}
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <button
            className={`${styles.CTAButton} ${styles.primaryButton}`}
            onClick={onJoinMeeting}
          >
            <Clock size={16} style={{ marginRight: '5px' }} />
            <span>Join Meeting</span>
          </button>
          <button
            className={`${styles.CTAButton} ${styles.secondaryButton}`}
            onClick={onViewDetails}
          >
            <ExternalLink size={16} style={{ marginRight: '5px' }} />
            <span>Details</span>
          </button>
          {/* <button
            className={`${styles.CTAButton} ${styles.secondaryButton}`}
            onClick={onOpenChat}
          >
            <MessageCircle size={16} />
            <span>Chat</span>
          </button> */}
        </div>
      </div>
      <div className={styles.rightContent}>
        <div className={styles.statusInfo}>
          <b className={styles.status}>Project in progress</b>
          <div className={styles.userInfo}>
            {/* <img
              className={styles.avatar}
              alt={technician.firstName || ""}
              src={technician?.profile_image || "/api/placeholder/24/24"}
            /> */}
            <div className={styles.userName}>
              {`${technician?.firstName || ''} ${technician?.lastName || ''}`}
            </div>
          </div>
        </div>
        <div className={styles.illustration}>
          <img className={styles.illustration} src={projectCTAImage} alt="Project in progress" />
        </div>
      </div>
    </div>
  );
};

export default ProjectCTA;