// InProgressCTA.js
import React from 'react';
import styles from './CTA.module.css';
import inProgressCTAImage from '../../../../assets/images/inProgressCTA.png';
import { FormatDescription } from './utilsCTA';

const InProgressCTA = ({
  job,
  onJoinMeeting,
  onViewDetails
}) => {
  const software = job?.software || {};
  const technician = job?.technician?.user || {};

  const formatedTime = () => {
    if (!job || !job.meeting_start_time) return '00:00';
    const meeting_start_time = new Date(job.meeting_start_time);
    let now_date = new Date();
    if (job.meeting_pause) {
      now_date = new Date(job.pause_start_time);
    }
    let seconds = (now_date.getTime() - meeting_start_time.getTime()) / 1000;
    seconds = job.total_pause_seconds ? seconds - job.total_pause_seconds : seconds;
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const pad = (n) => (isNaN(n) || n < 10 ? `0${n}` : n);
    return `${pad(hours)}:${pad(minutes)}`;
  }

  return (
    <div className={styles.CTAContainer}>
      <div className={styles.leftContent}>
        <div className={styles.contentTop}>
          <div className={styles.titleRow}>
            {/* <div className={styles.icon}>
              <img
                className={styles.iconImage}
                alt={software.name || ""}
                src={software.blob_image}
              />
            </div> */}
            <b className={styles.title}>
              <span>{software.name}</span>
              <span className={styles.subtitle}>{` · ${job.subOption || 'Technical Support'}`}</span>
            </b>
          </div>
          <div className={styles.description}>
            {<FormatDescription text={job?.issueDescription} seeMore={onViewDetails} />}
          </div>
        </div>
        <button
          className={`${styles.CTAButton} ${styles.primaryButton}`}
          onClick={onJoinMeeting}
        >
          <b>Go to Meeting</b>
        </button>
      </div>
      <div className={styles.rightContent}>
        <div className={styles.statusInfo}>
          <b className={styles.status}>Meeting in progress</b>
          <div className={styles.userInfo}>
            {/* <img
              className={styles.avatar}
              alt={technician.firstName || ""}
              src={technician?.profile_image || "/api/placeholder/24/24"}
            /> */}
            <div className={styles.userName}>
              {`${technician?.firstName || ''} ${technician?.lastName || ''}`}
            </div>
          </div>
          <b className={styles.timer}>{`${formatedTime()}`}</b>
        </div>
        <div className={styles.illustration}>
          <img className={styles.illustration} src={inProgressCTAImage} alt="Meeting in progress" />
        </div>
      </div>
    </div>
  );
};

export default InProgressCTA;

