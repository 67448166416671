import React from 'react';
import styled from 'styled-components';

const SatisfactionGuaranteeBox = () => {
  return (
    <SatisfactionGuaranteeContainer>
      <GuaranteeTitle>100% Satisfaction Guaranteed!</GuaranteeTitle>
      <GuaranteeText>
        Our commitment: If you're not 100% satisfied, we'll make it right or your money back—guaranteed.
      </GuaranteeText>
    </SatisfactionGuaranteeContainer>
  );
}


const SatisfactionGuaranteeContainer = styled.div`
  border: 1.5px dashed #00D4D5;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: left;
  background-color: #F0FEFF;
  transition: background-color 0.3s ease;
  max-width: 480px;

  &:hover {
    background-color: #E0F7FA;
  }
`;

const GuaranteeTitle = styled.h4`
  color: #00D4D5;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  margin: 0 !important;
`;

const GuaranteeText = styled.p`
  color: #333;
  font-size: 12px;
  margin: 0;
  word-wrap: break-word;
`;

export default SatisfactionGuaranteeBox;