import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TABLEHEAD_CELLS } from "constants/expandableJobContants";
import "./dashboard-style.css";
import { dateFormatter } from "utils/dashboardUtilities";
import { Box, Button, Collapse } from "@mui/material";
import EmptyDashboard from "./EmptyDashboard";
import ExpandableJobDetail from "../Table/ExpandableJobDetail";
import { JOB_STATUS } from "../../../constants/index";
import ShowButtonDecider from "./ShowButtonDecider";
import ChatButton from "./ChatButton";
import { useSocket } from "context/socketContext";
import * as JobApi from "../../../api/job.api";
import ReactToPrint from "react-to-print";
import * as BillApi from "../../../api/billingDetails.api";
import * as jobApi from "../../../api/job.api";
import { useJob } from "../../../context/jobContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import Invoice from "../../../components/Result/invoice";

export default function DashboardTabsDetails({
  user,
  dashboardTab,
  setLoader,
}) {
  const { userType = "" } = user;
  const { socket } = useSocket();

  const isCTA = React.useMemo(() => {
    if (!dashboardTab?.tabData) return null;
    return dashboardTab.tabData.find(job =>
      job.status === "Inprogress" || job.status === "long-job"
    );
  }, [dashboardTab?.tabData]);

  return (
    <>
      {isCTA && (
        <ShowButtonDecider
          jobData={isCTA}
          user={user}
          userType={userType}
          setLoader={setLoader}
          renderAsCTA={true}
        />
      )}

      {dashboardTab && dashboardTab?.tabData?.length > 0 ? (
        <TableContainer component={Paper} id="mui-table-container">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <DashboardTableHead userType={userType} dashboardTab={dashboardTab} />
            <DashboardTableBody
              jobsData={dashboardTab.tabData}
              userType={userType}
              user={user}
              socket={socket}
              setLoader={setLoader}
            />
          </Table>
        </TableContainer>
      ) : (
        <EmptyDashboard userType={userType} user={user} />
      )}
    </>
  );
}

const DashboardTableHead = React.memo(({ userType, dashboardTab }) => {
  return (
    <TableHead
      id="dashoard-table-head-style"
      style={{ whiteSpace: "nowrap" }}
    >
      <TableRow>
        {TABLEHEAD_CELLS.map((item) => {
          return userType == "technician" &&
            (item.value == "TECHNICIAN" || item.value == "INVOICE") ? null : (
            (item.value == "INVOICE" ?
              dashboardTab.tab == "completed-and-declined-tab"
                ?
                <TableCell className="mui-table-head" key={item.index}>{item.value}</TableCell>
                : null
              :
              <TableCell className="mui-table-head" key={item.index}>{item.value}</TableCell>)
          );
        })}
      </TableRow>
    </TableHead>
  );
});

const DashboardTableBody = React.memo(
  ({ jobsData, userType, user, socket, setLoader }) => {
    const [expandJobState, setExpandJobState] = React.useState({
      isOpen: false,
      data: {},
    });
    const [expandableJobId, setExpandableJobId] = React.useState("");
    const { fetchJob } = useJob();
    const [chargeData, setChargeData] = React.useState("");
    const [jobDataToPrint, setJobDataToPrint] = React.useState({});
    const [subscriptionData, setSubscriptionData] = React.useState({});
    const [allBillings, setAllBillings] = React.useState([]);
    let invoiceRefs = React.useRef();

    const ComponentRef = React.forwardRef((props, ref) => {
      return (
        <div ref={ref}>
          <Invoice
            chargeData={chargeData}
            job={jobDataToPrint}
            subscriptionData={subscriptionData}
            billingData={allBillings}
          />
        </div>
      );
    });

    React.useEffect(() => {
      if (jobsData.length > 0) {
        const filteredPendingJobs = jobsData.filter((item) => item.status == JOB_STATUS.PENDING);
        // TODO : Handle of sending socket for that
        const filteredAcceptedOrInProgressJobs = jobsData
          .filter((item) => item.status === JOB_STATUS.IN_PROGRESS || item.status === JOB_STATUS.ACCEPTED)
          .map((item) => item.id);

        if (filteredPendingJobs.length > 0) {
          setExpandJobState({ isOpen: !expandJobState.isOpen, data: filteredPendingJobs[0] });
          setExpandableJobId(filteredPendingJobs[0].id ?? "")
        }

        if (filteredAcceptedOrInProgressJobs.length > 0) {
          for (let jobId of filteredAcceptedOrInProgressJobs) {
            socket.emit("join", jobId)
          }
        }

      }
    }, [])

    /**
     * This function is updated to get the billing report data for job to show total amount in invoice according to the billing report.
     * @params : d(Type:object)
     * @response: no response
     * @author : Manibha
     **/
    const getDataToPrint = async (d) => {
      const billData = await BillApi.getBillingDetailsByJob(d.id);

      const jobupdate = await jobApi.retrieveJob(d.id);
      if (billData) {
        setChargeData(billData);
        setJobDataToPrint(jobupdate);
      }
      await fetchJob(d.id);
      if (d && d.payment_id && d.payment_id.includes("prod_")) {
        setSubscriptionData(user.customer.subscription);
      }
    };

    const openExpandableJobDetails = (data) => {
      if (data.status == JOB_STATUS.PENDING) {
        const isOpen =
          data.id == expandableJobId ? !expandJobState.isOpen : true;
        setExpandJobState({ isOpen: isOpen, data: data });
        setExpandableJobId(data.id);
      }
    };

    const push_to_profile_detail = (data) => {
      const newPageUrl = `/tech/${data}`;
      window.open(newPageUrl, "_blank");
    }

    const jobStatusDecider = (job) => {
      if (job.status == "long-job") {
        return "Project";
      }

      if (userType == "customer") {
        if (job.status == "Scheduled" && job?.schedule_accepted) {
          return "Scheduled & Accepted";
        }
        return job?.status;
      }

      if (userType == "technician") {
        const isTransfered =
          (job.is_transferred || job?.is_transferred_hire_expert) &&
          !job?.transfer_reference_job;
        const isDeclinedByTech =
          job?.tech_declined_ids &&
          (job?.tech_declined_ids).includes(user?.technician?.id);

        if (isTransfered && isDeclinedByTech) {
          return "Completed";
        }

        const isDeclinedByCustomer =
          job?.declinedByCustomer &&
          (job?.declinedByCustomer).includes(user?.technician?.id);
        if (isDeclinedByCustomer) {
          return "Declined By Customer";
        }
        if (isDeclinedByTech) {
          return "Declined By You";
        }
        const scheduledAccepted =
          job.status == "Scheduled" &&
          job.schedule_accepted &&
          job.schedule_accepted_by_technician == user?.id;
        if (scheduledAccepted) {
          return "Scheduled & Accepted";
        }
        return job?.status;
      }

      return job?.status;
    };

    return (
      <>
        <TableBody id="mui-table-body">
          {console.log("job data loaded", jobsData)}
          {jobsData && jobsData.length > 0
            ? jobsData.map((item) => {
              const skipJob =
                (item.is_transferred || item?.is_transferred_hire_expert) &&
                item?.transfer_reference_job;
              const isDeclinedByTech =
                item?.tech_declined_ids &&
                (item?.tech_declined_ids).includes(user?.technician?.id);
              if (isDeclinedByTech) {
                return null;
              }

              return (
                <>
                  <TableRow
                    className="mui-table-row"
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={(event) => openExpandableJobDetails(item)}
                  >
                    <TableCell
                      align="left"
                      className="dashboard-field-bold-style"
                    >
                      {item?.JobId ?? "None"}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="dashboard-field-bold-style"
                    >
                      {item?.software?.name ?? "None"}
                    </TableCell>
                    <TableCell align="left" className="dashboard-field-normal-style">
                      {dateFormatter(item?.createdAt) ?? "None"}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="dashboard-field-bold-style"
                    >
                      {jobStatusDecider(item) ?? "None"}
                    </TableCell>
                    <TableCell align="left" className="dashboard-field-normal-style">
                      {item?.customer?.user?.firstName ?? "None"}
                    </TableCell>
                    {userType == "customer" && (
                      <TableCell align="left" className="dashboard-field-normal-style">
                        {item?.technician?.user?.firstName ? <span title="View technician profile." className="tech-name-detail" onClick={() => push_to_profile_detail(item?.technician?.user?.uniqueName)}>{item?.technician?.user?.lastName + " " + item?.technician?.user?.firstName}</span> : item?.post_again_reference_technician?.firstName ? <span title="View technician profile." className="tech-name-detail" onClick={() => push_to_profile_detail(item?.post_again_reference_technician?.uniqueName)}>{item?.post_again_reference_technician?.lastName + " " + item?.post_again_reference_technician?.firstName}</span> : "None"}
                        {/* {item?.technician?.user?.firstName ?? "None"} */}
                      </TableCell>
                    )}
                    <TableCell align="left" className="dashboard-field-normal-style">
                      {item?.issueDescription ?? "None"}
                    </TableCell>
                    <TableCell align="left" style={{ width: "1rem" }} className="dashboard-field-normal-style">
                      <ShowButtonDecider
                        jobData={item}
                        user={user}
                        userType={userType}
                        setLoader={setLoader}
                      />
                    </TableCell>
                    <TableCell align="left" className="dashboard-field-normal-style">
                      <ChatButton job={item} user={user} />
                    </TableCell>
                    <TableCell align="left" className="dashboard-field-normal-style">
                      {userType == "customer" && item.status === JOB_STATUS.COMPLETED && (
                        <div className="invoice-download-btn d-flex justify-content-center" title="Print/Save Invoice">
                          <ReactToPrint
                            trigger={() => <FontAwesomeIcon icon={faPrint} />}
                            content={() => invoiceRefs.current}
                            onBeforeGetContent={() => getDataToPrint(item)}
                          />
                          <div style={{ display: "none" }}>
                            <ComponentRef text={item} ref={invoiceRefs} />
                          </div>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                  {item.status == "Pending" && userType == "customer" && item.id == expandableJobId && (
                    <ExpandJobDetails expandJobState={expandJobState} />
                  )}
                </>
              );
            })
            : null}
        </TableBody>
      </>
    );
  }
);

const ExpandJobDetails = ({ expandJobState }) => {
  return (
    <>
      {expandJobState.isOpen ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={expandJobState.isOpen} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <ExpandableJobDetail jobSummaryData={expandJobState.data} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};
