import React from 'react';

export const FormatDescription = ({ text, seeMore }) => {
  const maxLength = 60;

  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  return (
    <span>
      {text.slice(0, maxLength)}...
      <span
        onClick={seeMore}
        style={{ color: '#00cccc', cursor: 'pointer' }}
      >
        {" "} see details
      </span>
    </span>
  );
};
